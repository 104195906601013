import * as React from "react"
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "../components/hero"
import Welcome from "../components/welcome"
import Ideology from "../components/ideology"
import IdeologyImg from "../components/ideologyImg"
import Services from "../components/services"
import Insights from "../components/insights"
import Footer from "../components/footer"
import { ParallaxProvider } from "react-scroll-parallax"
import "../styles/styles.scss"

const IndexPage = ({location}) => (
  <div>
    <ParallaxProvider>
      <Seo />
      <Header />
      <Hero />
      <Welcome />
      <IdeologyImg />
      <Ideology />
      <Services />
      <Insights />
      <Footer location={location} />
    </ParallaxProvider>
  </div>
)

export default IndexPage
