import React from "react"
import { Parallax } from "react-scroll-parallax"

const Welcome = () => {
  return (
    <section className="welcome" id="welcome">
      <div className="container">
        <div className="content">
          <Parallax y={[20, -10]}>
            <h2>WELCOME</h2>
          </Parallax>
          <Parallax y={[20, -10]}>
            <p className="first">
              Breaking down complicated problems and generating original
              solutions is where we are in our element. Built for speed,
              security and scale, we are not satisified until all of the
              critical aspects are delivered and fine-tuned for your business.
              We are our biggest criticts.
            </p>
          </Parallax>
          <Parallax y={[20, -10]}>
            <p className="second">
              Our philosophy is to collaborate with the best technologists on
              the planet to deliver world-class solutions for our customers.
            </p>
          </Parallax>
        </div>
      </div>
    </section>
  )
}

export default Welcome
