import React from "react"
import { Parallax } from "react-scroll-parallax"
import ShapeSquare from "../images/svg/ShapeSquare.svg"
import ShapePolygon from "../images/svg/ShapePolygon.svg"
import ShapeEllipse from "../images/svg/ShapeEllipse.svg"

const Ideology = () => {
  return (
    <section className="ideology" id="ideology">
      <div className="container">
        <div className="content">
          <Parallax y={[-5, 0]} tagOuter="figure">
            <h2>OUR IDEOLOGY</h2>
          </Parallax>
          <div className="list-item mflip">
            <Parallax x={[-5, 0]} tagOuter="figure">
              <div className="item-heading">
                <h3>Effective Communication</h3>
                <p>
                  We work iteratively on solutions, listening and keeping you
                  aprised of our progress. Once we understand how your business
                  operates, we will leverage our know-how to give you an optimal
                  solution given the current technology landscape.
                </p>
              </div>
            </Parallax>
            <Parallax x={[5, -3]} tagOuter="figure">
              <ShapeSquare />
            </Parallax>
          </div>
          <div className="list-item">
            <Parallax x={[-5, -3]} tagOuter="figure">
              <ShapePolygon />
            </Parallax>
            <Parallax x={[5, 0]} tagOuter="figure">
              <div className="item-heading">
                <h3>Quality Design, Build & Delivery</h3>
                <p>
                  We provide you with clear designs from which we build and
                  deliver complete solutions. Automated deployments and
                  monitored infrastructure will leave you with a piece of mind
                  as the adoption grows.
                </p>
              </div>
            </Parallax>
          </div>
          <div className="list-item mflip">
            <Parallax x={[-5, 0]} tagOuter="figure">
              <div className="item-heading">
                <h3>Core Philosophy</h3>
                <p>
                  Solutions today should not be built from scratch. We use
                  reactive & responsive frameworks to design & build lasting,
                  well-performing solutions that leverage industry best
                  practices.
                </p>
              </div>
            </Parallax>
            <Parallax x={[5, -3]} tagOuter="figure">
              <ShapeEllipse />
            </Parallax>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Ideology
