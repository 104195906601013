import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Parallax } from "react-scroll-parallax"

const IdeologyImg = () => {
  return (
    <section className="ideology-img" id="ideologyImg">
      <div className="top-image">
        <Parallax y={[10, 0]}>
          <StaticImage src="../images/IdeologyImg.png" alt="Ideology" />
        </Parallax>
      </div>
    </section>
  )
}

export default IdeologyImg
