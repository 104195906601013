import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Insights = () => {
  return (
    <section className="insights" id="insights">
      <div className="container">
        <div className="content">
          <h2>INSIGHTS</h2>
          <div className="insights-list">
            <div className="insight-item">
              <a href="/insights/data-wrangling">
                <StaticImage
                  src="../images/insights/data-wrangling.jpg"
                  alt="AI Privacy"
                />
                <h3>Data Wrangling</h3>
              </a>
            </div>
            <div className="insight-item">
              <a href="/insights/ai-privacy">
                <StaticImage
                  src="../images/insights/ai-privacy.jpg"
                  alt="AI Privacy"
                />
                <h3>7 Ways Your Organization Can Protect Privacy in AI</h3>
              </a>
            </div>
            <div className="insight-item">
              <a href="/insights/ai-agents">
                <StaticImage
                  src="../images/insights/ai-agents.png"
                  alt="AI Agents"
                />
                <h3>AI Agents</h3>
              </a>
            </div>
            <div className="insight-item">
              <a href="/insights/retrieval-augmented-generation">
                <StaticImage
                  src="../images/insights/retrieval-augmented-generation.jpg"
                  alt="Retrieval Augmented Generation"
                />
                <h3>Retrieval Augmented Generation</h3>
              </a>
            </div>
            <div className="insight-item">
              <a href="/insights/ai-business">
                <StaticImage
                  src="../images/insights/ai-business-720.jpg"
                  alt="AI Considerations for Businesses"
                />
                <h3>AI Considerations for Businesses</h3>
              </a>
            </div>
            <div className="insight-item">
              <a href="/insights/gpt-3">
                <StaticImage src="../images/insights/gpt-3.jpg" alt="GPT-3" />
                <h3>GPT-3 and Large Language Models</h3>
              </a>
            </div>
            <div className="insight-item">
              <a href="/insights/akka-streams">
                <StaticImage
                  src="../images/insights/akka-streams-insight-720.jpg"
                  alt="Reactive Systems on .NET"
                />
                <h3>Controlled asynchrony with Reactive Streams</h3>
              </a>
            </div>
            <div className="insight-item">
              <a href="/insights/arch-native-images">
                <StaticImage
                  src="../images/insights/insight-proc-720.png"
                  alt="Containerized apps across modern processor architectures"
                />
                <h3>Multi-architecture docker builds</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Insights
