import React from "react"
import Card1 from "../images/card-1.png"
import Card2 from "../images/card-2.png"
import Card5 from "../images/card-5.png"

const Services = () => {
  return (
    <section className="services" id="services">
      <div className="container">
        <div className="content">
          <h2>SERVICES</h2>
          <div className="card-wrapper">
            <div className="card">
              <div
                className="front"
                style={{ backgroundImage: "url(" + Card1 + ")" }}
              >
                <h4>Products & Services</h4>
                <p>
                  We have helped business around the world build products and
                  services that have become part of their brand and identity.
                </p>
              </div>
              <div className="back">
                <div>
                  <p>
                    Everyone is put to the task to ensure a successful outcome.
                    Business analysts providing a clear definition of an
                    opportunity or a problem as well as an expected outcome.
                    Product designers and ux experts ensuring users are being
                    productive. Enginners building secure and resilient systems
                    that take advatage of modern infrastructure.
                  </p>
                  <a className="button" href="mailto:hello@techscoped.com">
                    Let's talk!
                  </a>
                </div>
              </div>
            </div>
            <div className="card">
              <div
                className="front"
                style={{ backgroundImage: "url(" + Card2 + ")" }}
              >
                <h4>Strategy & Consulting</h4>
                <p>
                  We have worked with business leadership to redefine their path
                  to growth and implement a successful transformation strategy.
                </p>
              </div>
              <div className="back">
                <div>
                  <p>
                    Identifying your unique value proposition while removing
                    ceremony is our starting point. From there, we will bring
                    our technology experts, product designers and marketers and
                    give your competitors one more run for their money.
                  </p>
                  <a className="button" href="mailto:hello@techscoped.com">
                    Let's talk!
                  </a>
                </div>
              </div>
            </div>
            <div className="card">
              <div
                className="front"
                style={{ backgroundImage: "url(" + Card5 + ")" }}
              >
                <h4>Security & Compliance</h4>
                <p>
                  We have been striking the right balance for our customers to
                  ensure their enterprise is secure while cost is managable.
                </p>
              </div>
              <div className="back">
                <div>
                  <p>
                    Organizations often do not take advantage of tools and
                    capabilities available to them. Whether it is establishing
                    and configuring the right security protocol, automating
                    compliance tasks or setting up monitoring tools, our
                    expertise spans years of being in the trenches so our
                    customers can stay focused on their core operations.
                  </p>
                  <a className="button" href="mailto:hello@techscoped.com">
                    Let's talk!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
