import React from "react"
import { Parallax } from "react-scroll-parallax"
import { StaticImage } from "gatsby-plugin-image"
import Arrow from "../images/svg/Arrow.svg"
import Typical from "react-typical"
const Banner = () => {
  return (
    <section className="hero" id="hero">
      <div className="container">
        <div className="content">
          <div className="left">
            <h1>
              Delivering Lasting Value <br />
              <b>Our Maxim</b>
            </h1>
            <p>
              Expertise in design & build of efficient, cost-effective solutions
              for better business outcomes.
            </p>
            <Parallax y={[-10, 10]}>
              <Arrow className="arrow" />
            </Parallax>
          </div>

          <div className="right">
            <div className="images">
              <Parallax y={[0, 20]}>
                <div className="hero-img">
                  <StaticImage src="../images/hero-1.jpg" />
                </div>
              </Parallax>
              <Parallax y={[0, -20]} x={[-70, -50]}>
                <div className="hero-img">
                  <StaticImage src="../images/hero-2.jpg" />
                </div>
              </Parallax>
              <Parallax y={[-30, -40]} x={[-20, 10]}>
                <div className="hero-img">
                  <StaticImage src="../images/hero-3.jpg" />
                </div>
              </Parallax>
            </div>
          </div>
        </div>
        <Typical
          className="hide-on-mobile"
          steps={[
            "plan & research",
            1500,
            "design & prototype",
            1500,
            "build & deploy",
            1500,
          ]}
          loop={Infinity}
          wrapper="p"
        />
      </div>
    </section>
  )
}

export default Banner
